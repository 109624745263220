.Game-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    row-gap: 1em;
}

.Game-top {
    display: flex;
    margin: 0 5em;
    flex-direction: row;
    justify-content: center;
    height: 6em;
    max-width: 60em;
    color: #fff;
    text-align: center;
}

.Game-display {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 50.5em;
    flex-wrap: wrap;
    gap: 0.5em;
}

.Game-bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 50.5em;
    color: #fff;
}

.Game-bottom-center {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: space-around;
    width: 10em;
}

.Game-bottom-side {
    width: 4em;
}

.Menu-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    height: 4em;
    width: 4em;
    border-radius: 0.5em;
    font-size: 1em;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    box-shadow: 0.1em 0.1em 0.1em 0px #00000080;
}

    .Menu-button:active {
        transform: translate(0px, 0.1em);
        box-shadow: none;
    }

.Button-orange {
    background-color: #ca6741;
}

    .Button-orange:hover {
        background-color: #d89074;
    }

.Button-blue {
    background-color: #457572;
}

    .Button-blue:hover {
        background-color: #7eb4b0;
    }

.Menu-icon {
    padding: 0;
    margin: 0;
    border: none;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    height: 3.0em;
    width: 3.0em;
}

.Img-flip {
    background-image: url("flip-icon.svg");
}

.Img-draw {
    background-image: url("draw-icon.svg");
}

.Img-exit {
    width: 2.5em !important;
    background-image: url("exit-icon.svg");
}

.Img-speaker {
    display: inline-flex;
    width: 1em;
    height: 1em;
    padding: 0;
    margin: 0 0.3em;
    border: none;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("speaker-icon.svg");
}

.Text-rough {
    color: #ffb699;
    filter: drop-shadow(0 0 0.1em #ff5b1a);
}

.Text-shine {
    color: #99fff8;
    filter: drop-shadow(0 0 0.6em #00ffee);
}

.Audio-link {
    cursor: pointer;
    text-decoration: underline;
}

.Game-Gard {
    transform: scale(0.01, 0.01);
}

.Animation-pop {
    animation: animate-pop 0.3s ease-in;
}

@keyframes animate-pop {
    0% {
        opacity: 0;
        transform: scale(0.01,0.01);
    }

    100% {
        opacity: 1;
        transform: scale(1,1);
    }
}