.Intro-container {
    position: absolute;
    background-color: #010405;
    height: 100vh;
    width: 100vw;
}

.Parallax-layer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
    max-width: 80vw;
    margin: 0 auto;
}

.Intro-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
}

.Intro-title h1 {
    font-size: 6em;
    color: #192d38 !important;
}

.Intro-title p {
    margin: 0;
    font-size: 1.5em;
    color: #192d38 !important;
}

.Intro-kindness {
    width: 50%;
    margin: 0 auto;
    color: white;
    font-size: 1.3em;
}

.Intro-kindness h1 {
    font-size: 3em;
}

.Intro-warrior {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    height: 100%;
    width: 45%;
    color: white;
}

.Intro-warrior h1 {
    margin: 0.1em 0.2em;
    font-size: 5em;
    text-align: right;
    color: #192d38 !important;
}

.Intro-warrior-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 55%;
}

    .Intro-warrior-content p {
        color: white;
        font-size: 1.3em;
        width: 80%;
    }

.Intro-warrior-button {
    align-items: center !important;
}
.Intro-about {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 auto;
    width: 80vw;
}

    .Intro-about h1 {
        font-size: 5em;
        color: #192d38 !important;
    }

    .Intro-about p {
        font-size: 1.3em;
    }

.Intro-logos {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    width: 80vw;
    margin: 0 auto;
}

.Intro-menu-button {
    outline: none;
    border: none;
    height: 4em;
    width: 16em;
    border-radius: 3em;
    font-size: 2em;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    background-color: #ca6741;
    box-shadow: 0.1em 0.1em 0.1em 0px #00000080;
}

    .Intro-menu-button:active {
        transform: translate(0px, 0.1em);
        box-shadow: none;
    }

    .Intro-menu-button:hover {
        background-color: #d89074;
    }

.Title-layer {
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.Img-layer1 {
    background-image: url('./title-layers/title-layer1.png');
}

.Img-layer2 {
    background-image: url('./title-layers/title-layer2.png');
}

.Img-layer3 {
    background-image: url('./title-layers/title-layer3.png');
}

.Img-layer4 {
    background-image: url('./title-layers/title-layer4.png');
}

.Img-layer5 {
    background-image: url('./title-layers/title-layer5.png');
}

.Img-layer6 {
    background-image: url('./title-layers/title-layer6.png');
}

.Img-layer7 {
    background-image: url('./title-layers/title-layer7.png');
}

.Img-layer8 {
    background-image: url('./title-layers/title-layer8.png');
}

.Img-layer9 {
    background-image: url('./title-layers/title-layer9.png');
}

.Img-logo {
    margin: 1em;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
}
.Logo1 {
    height: 6em;
    width: 15em;
    background-image: url('./nokiiwin-logo.png');
}

.Logo2 {
    height: 8em;
    width: 8.5em;
    background-image: url('./biskaabiiyaang-logo.png');
}

.Logo3 {
    height: 6em;
    width: 15em;
    background-image: url('./univirtual-logo.png');
}

.Logo4 {
    height: 6em;
    width: 20em;
    background-image: url('./canadaheritage-logo.jpeg');
}