.card_box, .card_box *{
    margin: 0;
    padding: 0;
}

.card_box {
    perspective: 600px;
    height: 11em;
    width: 11em;
}

.card_wrapper {
    transform-style: preserve-3d;
    cursor: pointer;
}

.align-center {
    display: table-cell;
    vertical-align: middle;
    font-size: 0.8em;
    font-weight: bold;
    /*font-family: Arial;*/
}

.card_face {
    backface-visibility: hidden;
    position: absolute;
    Top: 0;
    Left: 0;
    padding: 0.5em 2.2em;
    height: 10em;
    width: 6.6em;
    display: table;
    text-align: center;
    background-image: url('sweetgrass-card-blank_rough.png');
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    filter: drop-shadow(0 0 0.7em #ff5b1a);
}

.card.is-top {
    position: static;
}

.c_back {
    background-image: url('sweetgrass-card-blank_shine.png') !important;
    filter: drop-shadow(0 0 0.6em #00ffee) !important;
    transform: rotateY(180deg);
}

.anim_forward {
    animation: rotate 0.5s linear both;
}

.anim_reverse {
    animation: rotate-inverse 0.5s linear both;
}

@keyframes rotate {
    0% {
        transform: rotateY(0);
    }

    70% {
        transform: rotateY(90deg);
    }

    100% {
        transform: rotateY(180deg);
    }
}

@keyframes rotate-inverse {
    0% {
        transform: rotateY(180deg);
    }

    70% {
        transform: rotateY(90deg);
    }

    100% {
        transform: rotateY(0);
    }
}
