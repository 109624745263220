body {
    margin: 0;
    font-family: Montserrat, Tahoma, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1.7vmin;
}

h1 {
    margin: 0.3em;
    font-size: 3em;
    color: #457572;
    font-family: Amatic SC;
}

p {
    margin: 1em;
}
