.Conclusion-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}
  
.Conclusion-item {
    margin: auto;
}