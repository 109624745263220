.Activity-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.Activity-item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Activity-top h1 {
    color: #ffffff;
}

.Activity-game {
    padding: 5em 5em;
    min-width: 65vw;
    background-image: url('activity-bg.png');
    background-size: 100% 100%;
    background-origin: border-box;
    background-repeat: no-repeat;
}

